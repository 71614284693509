/**
 * We have two main ways to specify a chord indepently of the instrument:
 *
 * a) <name, variant> - e.g., Cm, C7, Cm7, C7sus4, etc.
 * b) [notes] - the notes that make up the chord
 *
 * In addition to this, we may have additional information that are specific to an instrument, e.g.,
 *  - fingering on the guitar and/or bass, keyboard and so on
 */

import type { NoteName } from '@tunasong/schemas'

export interface ChordVariant {
  root: NoteName
  variant: ChordType
}

export interface ChordNotes {
  name: string
  notes: NoteName[]
}

export type Chord = ChordVariant | ChordNotes

export function isChordVariant(node: unknown): node is ChordVariant {
  return Boolean((node as ChordVariant)?.root && typeof (node as ChordVariant)?.variant !== 'undefined') // "" is a valid value
}

export function isChordNotes(node: unknown): node is ChordNotes {
  return Boolean(node && typeof node === 'object' && 'notes' in node && Array.isArray(node.notes))
}

const triads = {
  '': {
    shortName: '',
    intervals: ['I', 'III', 'V'],
  },
  m: {
    shortName: 'm',
    intervals: ['I', 'bIII', 'V'],
  },
  aug: {
    shortName: 'aug',
    intervals: ['I', 'III', '#V'],
  },
  dim: {
    shortName: 'dim',
    intervals: ['I', 'bIII', 'bV'],
  },
  sus2: {
    shortName: 'sus2',
    intervals: ['I', 'II', 'V'],
  },
  sus4: {
    shortName: 'sus4',
    intervals: ['I', 'IV', 'V'],
  },
  sus2sus4: {
    shortName: 'sus2',
    intervals: ['I', 'II', 'IV', 'V'],
  },
  /** Power chord */
  '5': {
    shortName: '5',
    intervals: ['I', 'V'],
  },
}

/** @todo we can probably generate these */
const elements = {
  b5: {
    shortName: 'b5',
    intervals: ['bV'],
  },
  b6: {
    shortName: 'b6',
    intervals: ['bVI'],
  },
  '6': {
    shortName: '6',
    intervals: ['VI'],
  },
  '7': {
    shortName: '7',
    intervals: ['bVII'],
  },
  /** Contains all the add intervals - i.e., 3, 5 and a flat 7th */
  '9': {
    shortName: '9',
    intervals: ['II', 'bVII'],
  },
  maj7: {
    shortName: '7',
    intervals: ['VII'],
  },
  add9: {
    shortName: 'add9',
    intervals: ['II'],
  },
  add11: {
    shortName: 'add9',
    intervals: ['IV'],
  },
}

const types = { ...triads, ...elements }

export type NoteElement = keyof typeof elements
export type ChordElement = keyof typeof types
export type TriadType = keyof typeof triads

export const ALL_CHORD_TYPES = types
export const ALL_CHORD_TRIADS = triads
export const ALL_CHORD_TRIAD_TYPES = Object.keys(triads) as TriadType[]
export const ALL_CHORD_ELEMENTS = elements
export const ALL_CHORD_ELEMENT_TYPES = Object.keys(elements) as NoteElement[]

/** @todo should be managed automatically, but difficult because we compose from the different types */
export type ChordType =
  | ''
  | 'm'
  | 'aug'
  | 'dim'
  | 'sus2'
  | 'sus4'
  | 'sus2sus4'
  | '7sus2'
  | '7sus4'
  | '7sus2sus4'
  | '5'
  | 'b5'
  | 'b6'
  | '6'
  | '7'
  | '9'
  | 'maj7'
  /* Minors */
  | 'mb5'
  | 'mb6'
  | 'm6'
  | 'm7'
  | 'mmaj7'
  /* aug */
  | 'augb5'
  | 'augb6'
  | 'aug6'
  | 'aug7'
  | 'augmaj7'
  /* dim */
  | 'dimb5'
  | 'dimb6'
  | 'dim6'
  | 'dim7'
  | 'dimmaj7'
