import { convertDuration } from './convert-duration.js'
import { toEventDuration, toTicks } from './duration.js'
import { noteEventString } from './note-event-string.js'
import { parseEasyScoreEvent, parseEasyScoreEvents } from './parse-event.js'

export * from './event.js'

export const EasyScore = {
  parseEvent: parseEasyScoreEvent,
  parseEvents: parseEasyScoreEvents,
  toTicks,
  toEventDuration,
  convertDuration,
  noteEventString,
}
