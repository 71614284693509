import { type Octave } from '../note/index.js'
import { toEventDuration, type EasyScoreDuration } from './duration.js'
import { type NoteEvent } from '../progression/event.js'
import type { NoteName } from '@tunasong/schemas'

export const parseEasyScoreEvent = (event: string): NoteEvent => {
  /**
   * A note event is on the form Note:Accidental:Octave/Duration
   *
   * e.g., C, C4/8, C5/q, C#4/16 and so on
   */

  const duration = (event.split('/')[1] as EasyScoreDuration) || 'q'
  const note = event[0]
  const accidential = event[1] === '#' ? '#' : event[1] === 'b' ? 'b' : undefined
  /** We are using isNaN and NOT Number.isNaN to coherce the value here */
  const octave =
    (!isNaN(event[1] as never)
      ? Number(event[1])
      : event[1] !== '/' && !isNaN(event[2] as never)
        ? Number(event[2])
        : undefined) ?? 4

  return {
    type: 'note',
    note: `${note}${accidential || ''}` as NoteName,
    duration: toEventDuration(duration),
    octave: octave as Octave,
  }
}

/** events is a comma separated string */
export const parseEasyScoreEvents = (events: string): NoteEvent[] =>
  events
    .split(/[,;\ ]/)
    .filter(Boolean)
    .map(e => parseEasyScoreEvent(e.trim()))
