/** Scale degrees apply only to MAJOR and MINOR scales */

/** https://en.wikipedia.org/wiki/Diatonic_scale#Major_scale */
export const SCALE_DEGREES = {
  I: 'Tonic',
  II: 'Supertonic',
  III: 'Mediant',
  IV: 'Subdominant',
  V: 'Dominant',
  VI: 'Submediant',
  VII: 'Leading note',
  VIII: 'Octave Tonic',
}

export type ScaleDegree = keyof typeof SCALE_DEGREES

export const SCALE_DEGREE_NAMES = Object.keys(SCALE_DEGREES) as ScaleDegree[]

export const getScaleDegreeName = (d: ScaleDegree) => SCALE_DEGREE_NAMES[SCALE_DEGREE_NAMES.indexOf(d)]

/** Intervals starts with interval of the tonic (i.e., ) */
/** Strip sharps or flats to get the index */
export const getScaleIndex = (degree: ScaleDegree) => SCALE_DEGREE_NAMES.indexOf(degree)

export const getScaleDegreeByIndex = (index: number) => SCALE_DEGREE_NAMES[index]

/** Get the interval from the tonic */
export const getScaleDegreeInterval = (intervals: number[], degree: ScaleDegree) => {
  const sum = intervals.reduce((s, val) => s + val, 0)
  if (sum !== 12 || intervals.length !== 8) {
    throw new Error(
      `getScaleDegreeInterval only supports scales that start with the tonic interval (0) and ends with the I and sums to 12 steps`
    )
  }

  const index = getScaleIndex(degree)
  /** Sum up until and including the indexed element */
  const slice = intervals.slice(0, index + 1)
  return (slice.reduce((s, val) => s + val, 0) + 12) % 12
}
