import type { NoteName } from '@tunasong/schemas'
import { getNoteChromaticIndex } from '../note/index.js'

export const noteSignature = (notes: NoteName[]) => {
  const noDupes = [...new Set(notes)]
  /** Map note to chromatic index and sort them */
  return noDupes
    .map(n => getNoteChromaticIndex(n))
    .sort((a, b) => (a === b ? 0 : a < b ? -1 : 1))
    .join('-')
}
