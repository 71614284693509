import { type ChordProgression } from '../types.js'

const progressions: ChordProgression[] = [
  {
    name: 'I-IV-V',
    tags: ['country', 'blues', 'pop', 'rock', 'catchy', 'high-energy'],
    strength: 1.0,
    sequence: [
      { degree: 'I', type: '' },
      { degree: 'IV', type: '' },
      { degree: 'V', type: '' },
    ],
  },
  {
    name: 'Sensitive',
    tags: ['punk', 'sensitive', 'comforting', 'hopeful', 'confessional', 'sentimental'],
    strength: 0.9,
    sequence: [
      { degree: 'I', type: '' },
      { degree: 'V', type: '' },
      { degree: 'VI', type: 'm' },
      { degree: 'IV', type: '' },
    ],
  },
  {
    name: 'Jazz kat',
    tags: ['jazz'],
    strength: 0.9,
    sequence: [
      { degree: 'II', type: 'm' },
      { degree: 'V', type: '' },
      { degree: 'I', type: '' },
    ],
  },
  {
    /** The Canon: I – V – vi – iii – IV – I – IV – V Progression  */
    name: 'The Canon',
    tags: ['bittersweet', 'nostalgia'],
    strength: 0.9,
    sequence: [
      { degree: 'I', type: '' },
      { degree: 'V', type: '' },
      { degree: 'VI', type: 'm' },
      { degree: 'III', type: 'm' },
      { degree: 'IV', type: '' },
      { degree: 'I', type: '' },
      { degree: 'IV', type: '' },
      { degree: 'V', type: '' },
    ],
  },

  // From https://www.musical-u.com/learn/exploring-common-chord-progressions/
  /**
   *  I – vi – IV – V *
      I – IV – V – IV *
      vi – IV – I – V *
      I – IV – ii – V *
      I – IV – I – V *
      I – ii – iii – IV – V *
      I – III – IV – iv *
      i – V – i – iv *
      vi – V – IV – III (also called the Andalusian Cadence, as Guitar Endeavour explains)
   */
  {
    name: 'Andalusian Cadence',
    tags: [],
    strength: 0.5,
    sequence: [
      { degree: 'IV', type: 'm' },
      { degree: 'V', type: '' },
      { degree: 'IV', type: '' },
      { degree: 'III', type: '' },
    ],
  },
  {
    name: 'i - V - i - iv',
    tags: [],
    strength: 0.5,
    sequence: [
      { degree: 'I', type: 'm' },
      { degree: 'V', type: '' },
      { degree: 'I', type: 'm' },
      { degree: 'IV', type: 'm' },
    ],
  },
  {
    name: 'I - III - IV - iv',
    tags: [],
    strength: 0.5,
    sequence: [
      { degree: 'I', type: '' },
      { degree: 'III', type: 'm' },
      { degree: 'IV', type: '' },
      { degree: 'IV', type: 'm' },
    ],
  },
  {
    name: 'I - ii - iii - IV - V',
    tags: [],
    strength: 0.5,
    sequence: [
      { degree: 'I', type: '' },
      { degree: 'II', type: 'm' },
      { degree: 'III', type: 'm' },
      { degree: 'IV', type: '' },
      { degree: 'V', type: '' },
    ],
  },
  {
    name: 'I - IV - I - V',
    tags: [],
    strength: 0.5,
    sequence: [
      { degree: 'I', type: '' },
      { degree: 'IV', type: '' },
      { degree: 'I', type: '' },
      { degree: 'V', type: '' },
    ],
  },
  {
    name: 'I - IV - ii - V',
    tags: [],
    strength: 0.5,
    sequence: [
      { degree: 'I', type: '' },
      { degree: 'IV', type: '' },
      { degree: 'II', type: 'm' },
      { degree: 'V', type: '' },
    ],
  },
  {
    name: 'vi - IV - I - V',
    tags: [],
    strength: 0.5,
    sequence: [
      { degree: 'VI', type: 'm' },
      { degree: 'IV', type: '' },
      { degree: 'I', type: '' },
      { degree: 'V', type: '' },
    ],
  },
  {
    name: 'I - IV - V - IV',
    tags: [],
    strength: 0.5,
    sequence: [
      { degree: 'I', type: '' },
      { degree: 'VI', type: '' },
      { degree: 'V', type: '' },
      { degree: 'IV', type: '' },
    ],
  },
  {
    name: 'I - vi - IV - V',
    tags: [],
    strength: 0.5,
    sequence: [
      { degree: 'I', type: '' },
      { degree: 'VI', type: 'm' },
      { degree: 'IV', type: '' },
      { degree: 'V', type: '' },
    ],
  },
]

export default progressions
