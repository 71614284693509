import type { NoteName, Scale } from '@tunasong/schemas'
import { getNote } from '../note/note-util.js'
import { type ScaleDegree } from './scale-degree.js'
import ScaleLib from './scale.js'

export interface ComplementaryScales {
  major: NoteName
  minor: NoteName
  degree?: ScaleDegree | null
}

/** Calculate the Circle of Fifths */
export const circleOfFifths = (start: NoteName = 'C') => {
  const circle: ComplementaryScales[] = []
  let note: NoteName = start
  const scale: Scale = { root: start, type: 'major' }
  for (let i = 0; i < 12; i++) {
    const major = getNote(note, 'I') // No-op, but here for completeness
    const minor = getNote(note, 'VI')

    const degree = ScaleLib.getDegree(scale, major)

    circle.push({
      major,
      minor,
      degree,
    })
    note = getNote(note, 'V')
  }
  return circle
}
