/** Factored out of library to avoid circular references */

import { logger } from '@tunasong/models'
import { NoteLib } from '../note/index.js'
import { type Chord, isChordNotes, isChordVariant } from './chord-types.js'

export const getName = (chord?: Chord, transposeSemiTones = 0) => {
  if (!chord) {
    return '-'
  }
  if (isChordVariant(chord)) {
    const root = NoteLib.transpose(chord.root, transposeSemiTones)
    const variant = chord.variant ? chord.variant : ''

    return `${root}${variant}`
  } else if (isChordNotes(chord)) {
    const isTransposed = transposeSemiTones > 0 || transposeSemiTones < 0
    const transposeSign = transposeSemiTones > 0 ? '+' : ''
    const transposeLabel = isTransposed ? ` (${transposeSign}${transposeSemiTones})` : ''
    return `${chord.name}${transposeLabel}`
  } else {
    logger.error(`Unknown chord shape - cannot transpose`, chord)
    return '- UNKNOWN -'
  }
}
