import type { NoteName } from '@tunasong/schemas'
import { NoteLib } from '../note/index.js'
import { ALL_CHORDS } from './all-chords.js'
import { isChordNotes, type Chord } from './chord-types.js'
import { getName } from './get-name.js'

export const getChordNotes = (name: string): NoteName[] => {
  const chordInfo = ALL_CHORDS[name]
  return chordInfo ? chordInfo.builder.getNotes() : []
}

export const getNotes = (chordOrChords: Chord | Chord[]): NoteName[] => {
  const allChords = Array.isArray(chordOrChords) ? chordOrChords : [chordOrChords]
  return NoteLib.sort([...new Set(allChords.flatMap(c => (isChordNotes(c) ? c.notes : getChordNotes(getName(c)))))])
}
