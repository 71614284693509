import { isGuitarChord } from '../guitar/guitar-chord.js'
import { GuitarLib } from '../guitar/index.js'
import type { NoteOctave } from '../note/index.js'
import * as NoteLib from '../note/note-lib.js'
import { ScaleLib } from '../scale/index.js'
import { type Chord, type ChordVariant, isChordNotes, isChordVariant } from './chord-types.js'
import { noteSignature } from './util.js'

/** Chord interface */

export const transpose = <T extends Chord>(chord: T, semiTones: number): T => {
  if (semiTones === 0) {
    return chord
  }
  if (isGuitarChord(chord)) {
    return {
      ...chord,
      name: getName(chord, semiTones),
      notes: chord.notes.map(n => NoteLib.transpose(n, semiTones)),
      fingering: GuitarLib.transpose(chord.fingering, semiTones),
    }
  }
  if (isChordNotes(chord)) {
    return {
      ...chord,
      name: getName(chord),
      notes: chord.notes.map(n => NoteLib.transpose(n, semiTones)),
    }
  } else if (isChordVariant(chord)) {
    return {
      ...chord,
      root: NoteLib.transpose(chord.root, semiTones),
    }
  } else {
    logger.error(`Unknown chord shape - cannot transpose`, chord)
    return chord
  }
}

import { ALL_CHORDS, notesToChords } from './all-chords.js'
import { getName } from './get-name.js'
export { getName }

export { getNotes } from './get-notes.js'

import { logger } from '@tunasong/models'
import type { NoteName, Scale } from '@tunasong/schemas'
import { chordEquals } from './equals.js'
export { chordEquals as equals }

/** Custom chords are filtered on their specified name. We should perhaps filter on the notes? */
export const dedupe = (chords: Chord[]) => {
  const chordMap: Record<string, Chord> = {}
  chords.forEach(c => (chordMap[getName(c)] = c))
  return Object.values(chordMap)
}

export const getChordNames = () => Object.keys(ALL_CHORDS)

export const fromName = (name: string): ChordVariant | undefined => {
  const chordInfo = ALL_CHORDS[name.trim()]
  return chordInfo ? chordInfo.chord : undefined
}

interface FromNotesOptions {
  /** If scale is specified, only chords whose root are in the scale are returned */
  scale?: Scale
  /** include bb, ## and E# and B#. @default to false */
  includeUncommon?: boolean
}

const isUncommon = (note: NoteName) => {
  const uncommon = ['E#', 'B#']
  return uncommon.includes(note) || note.endsWith('bb') || note.endsWith('##')
}

export const fromNotes = (
  notes: (NoteName | NoteOctave)[],
  { scale, includeUncommon }: FromNotesOptions = { includeUncommon: false }
) => {
  const sign = noteSignature(notes.map(NoteLib.toNote))
  const chords = (notesToChords[sign] || []).filter(c => (includeUncommon ? true : !isUncommon(c.root)))
  const scaleNotes = scale ? ScaleLib.getNotes(scale) : null
  return scaleNotes ? chords.filter(c => scaleNotes.includes(c.root)) : chords
}

export const unique = (chords: Chord[]) => {
  const names = [...new Set(chords.map(c => getName(c)))]
  return names.map(name => chords.find(c => getName(c) === name) as Chord).filter(Boolean)
}
