export const OPEN_STRING = 0
export const MUTED_STRING = -1

export const CHORD_SHAPES = {
  C9: [
    { fret: 3, str: 1 },
    { fret: 3, str: 2 },
    { fret: 3, str: 3 },
    { fret: 2, str: 4 },
    { fret: 3, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  D: [
    { fret: 2, str: 1 },
    { fret: 3, str: 2 },
    { fret: 2, str: 3 },
    { fret: OPEN_STRING, str: 4 },
    { fret: MUTED_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  Dsus2: [
    { fret: OPEN_STRING, str: 1 },
    { fret: 3, str: 2 },
    { fret: 2, str: 3 },
    { fret: OPEN_STRING, str: 4 },
    { fret: MUTED_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  Dsus4: [
    { fret: 3, str: 1 },
    { fret: 3, str: 2 },
    { fret: 2, str: 3 },
    { fret: OPEN_STRING, str: 4 },
    { fret: MUTED_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  D7: [
    { fret: 2, str: 1 },
    { fret: 1, str: 2 },
    { fret: 2, str: 3 },
    { fret: OPEN_STRING, str: 4 },
    { fret: MUTED_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  D7sus2: [
    { fret: OPEN_STRING, str: 1 },
    { fret: 1, str: 2 },
    { fret: 2, str: 3 },
    { fret: OPEN_STRING, str: 4 },
    { fret: MUTED_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  D7sus4: [
    { fret: 3, str: 1 },
    { fret: 1, str: 2 },
    { fret: 2, str: 3 },
    { fret: OPEN_STRING, str: 4 },
    { fret: MUTED_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  Ddim: [
    { fret: 1, str: 1 },
    { fret: 3, str: 2 },
    { fret: 1, str: 3 },
    { fret: OPEN_STRING, str: 4 },
    { fret: MUTED_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  Daug: [
    { fret: 2, str: 1 },
    { fret: 3, str: 2 },
    { fret: 3, str: 3 },
    { fret: OPEN_STRING, str: 4 },
    { fret: MUTED_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  E: [
    { fret: OPEN_STRING, str: 1 },
    { fret: OPEN_STRING, str: 2 },
    { fret: 1, str: 3 },
    { fret: 2, str: 4 },
    { fret: 2, str: 5 },
    { fret: OPEN_STRING, str: 6 },
  ],
  E7: [
    { fret: OPEN_STRING, str: 1 },
    { fret: OPEN_STRING, str: 2 },
    { fret: 1, str: 3 },
    { fret: OPEN_STRING, str: 4 },
    { fret: 2, str: 5 },
    { fret: OPEN_STRING, str: 6 },
  ],
  Eadd9: [
    { fret: 2, str: 1 },
    { fret: OPEN_STRING, str: 2 },
    { fret: 1, str: 3 },
    { fret: 2, str: 4 },
    { fret: 2, str: 5 },
    { fret: OPEN_STRING, str: 6 },
  ],
  Em: [
    { fret: OPEN_STRING, str: 1 },
    { fret: OPEN_STRING, str: 2 },
    { fret: OPEN_STRING, str: 3 },
    { fret: 2, str: 4 },
    { fret: 2, str: 5 },
    { fret: OPEN_STRING, str: 6 },
  ],
  Em7: [
    { fret: 3, str: 1 },
    { fret: 3, str: 2 },
    { fret: OPEN_STRING, str: 3 },
    { fret: 2, str: 4 },
    { fret: 2, str: 5 },
    { fret: OPEN_STRING, str: 6 },
  ],
  A: [
    { fret: OPEN_STRING, str: 1 },
    { fret: 2, str: 2 },
    { fret: 2, str: 3 },
    { fret: 2, str: 4 },
    { fret: OPEN_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  Aaug: [
    { fret: 1, str: 1 },
    { fret: 2, str: 2 },
    { fret: 2, str: 3 },
    { fret: 3, str: 4 },
    { fret: OPEN_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  Asus2: [
    { fret: OPEN_STRING, str: 1 },
    { fret: OPEN_STRING, str: 2 },
    { fret: 2, str: 3 },
    { fret: 2, str: 4 },
    { fret: OPEN_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  Asus2sus4: [
    { fret: OPEN_STRING, str: 1 },
    { fret: OPEN_STRING, str: 2 },
    { fret: 2, str: 3 },
    { fret: OPEN_STRING, str: 4 },
    { fret: OPEN_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  Asus4: [
    { fret: OPEN_STRING, str: 1 },
    { fret: 3, str: 2 },
    { fret: 2, str: 3 },
    { fret: 2, str: 4 },
    { fret: OPEN_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  A7: [
    { fret: OPEN_STRING, str: 1 },
    { fret: 2, str: 2 },
    { fret: OPEN_STRING, str: 3 },
    { fret: 2, str: 4 },
    { fret: OPEN_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  Am: [
    { fret: OPEN_STRING, str: 1 },
    { fret: 1, str: 2 },
    { fret: 2, str: 3 },
    { fret: 2, str: 4 },
    { fret: OPEN_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  Amb6: [
    { fret: 1, str: 1 },
    { fret: 1, str: 2 },
    { fret: 2, str: 3 },
    { fret: 2, str: 4 },
    { fret: OPEN_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  Am7: [
    { fret: OPEN_STRING, str: 1 },
    { fret: 1, str: 2 },
    { fret: OPEN_STRING, str: 3 },
    { fret: 2, str: 4 },
    { fret: OPEN_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  G: [
    { fret: 3, str: 1 },
    { fret: 3, str: 2 },
    { fret: OPEN_STRING, str: 3 },
    { fret: OPEN_STRING, str: 4 },
    { fret: 2, str: 5 },
    { fret: 3, str: 6 },
  ],
  C: [
    { fret: OPEN_STRING, str: 1 },
    { fret: 1, str: 2 },
    { fret: OPEN_STRING, str: 3 },
    { fret: 2, str: 4 },
    { fret: 3, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  Cmaj7: [
    { fret: OPEN_STRING, str: 1 },
    { fret: OPEN_STRING, str: 2 },
    { fret: OPEN_STRING, str: 3 },
    { fret: 2, str: 4 },
    { fret: 3, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  Bdim: [
    { fret: MUTED_STRING, str: 1 },
    { fret: 3, str: 2 },
    { fret: 4, str: 3 },
    { fret: 3, str: 4 },
    { fret: 2, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  Dm: [
    { fret: 1, str: 1 },
    { fret: 3, str: 2 },
    { fret: 2, str: 3 },
    { fret: OPEN_STRING, str: 4 },
    { fret: MUTED_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  Fmaj7: [
    { fret: OPEN_STRING, str: 1 },
    { fret: 1, str: 2 },
    { fret: 2, str: 3 },
    { fret: 3, str: 4 },
    { fret: MUTED_STRING, str: 5 },
    { fret: MUTED_STRING, str: 6 },
  ],
  E5: [
    { fret: MUTED_STRING, str: 1 },
    { fret: MUTED_STRING, str: 2 },
    { fret: MUTED_STRING, str: 3 },
    { fret: 2, str: 4 },
    { fret: 2, str: 5 },
    { fret: OPEN_STRING, str: 6 },
  ],
}

export type ChordShape = keyof typeof CHORD_SHAPES
