import { meterToString, type Rhythm } from '@tunasong/models'

export const rhythmSummary = (rhythm: Rhythm) => {
  const summary: string[] = []
  if (rhythm.tempo) {
    summary.push(`${rhythm.tempo} BPM`)
  }
  if (rhythm.meter) {
    summary.push(`${meterToString(rhythm.meter)}`)
  }
  return summary.join(', ')
}
