import { NOTE_NAMES } from '../note/index.js'
import { ChordBuilder } from './chord-builder.js'
import { ALL_CHORD_ELEMENT_TYPES, ALL_CHORD_TRIAD_TYPES, type ChordVariant } from './chord-types.js'
import { getName } from './get-name.js'

/** Generate a map from note names to Chords */
export const notesToChords: { [notesKey: string]: ChordVariant[] } = {}
export const ALL_CHORDS: { [name: string]: { chord: ChordVariant; builder: ChordBuilder } } = {}

for (const note of NOTE_NAMES) {
  for (const triad of ALL_CHORD_TRIAD_TYPES) {
    const c = ChordBuilder.create(note).add(triad)
    notesToChords[c.getNoteSignature()] = notesToChords[c.getNoteSignature()] || []
    notesToChords[c.getNoteSignature()].push(c.getChord())
    ALL_CHORDS[getName(c.getChord())] = { chord: c.getChord(), builder: c }

    for (const element of ALL_CHORD_ELEMENT_TYPES) {
      /** Suspended chords come last - e.g., C7sus2 */
      const isSuspended = triad.match(/sus/)
      const e1 = isSuspended ? element : triad
      const e2 = isSuspended ? triad : element
      const c2 = ChordBuilder.create(note).add(e1).add(e2)
      notesToChords[c2.getNoteSignature()] = notesToChords[c2.getNoteSignature()] || []
      notesToChords[c2.getNoteSignature()].push(c2.getChord())
      ALL_CHORDS[getName(c2.getChord())] = { chord: c2.getChord(), builder: c2 }
    }
  }
}
