import type { Scale } from '@tunasong/schemas'
import { ChordLib, type Chord, type ChordVariant } from '../chord/index.js'
import ScaleLib from '../scale/scale.js'
import { CHORD_PROGRESSIONS } from './library/index.js'
import { type ChordMatch } from './types.js'

const chordCache: Record<string, ChordMatch[]> = {}
const cacheKey = (current?: Chord, next?: Chord, scale?: Scale) =>
  `${ChordLib.getName(current)}-${ChordLib.getName(next)}-${ScaleLib.getName(scale)}`

export const randomChordInKey = (scale: Scale): ChordMatch => {
  const idx = Math.floor(Math.random() * CHORD_PROGRESSIONS.length)
  const progression = CHORD_PROGRESSIONS[idx]
  const chord = { name: progression.name, score: 1.0, chord: ScaleLib.getChord(scale, progression.sequence[0].degree) }
  return chord
}

/** @todo perhaps not select totally randomly here... */
export const randomChord = () => {
  const allChords = ChordLib.getChordNames()
  return ChordLib.fromName(allChords[Math.floor(Math.random() * allChords.length)])
}

export const randomChords = (num: number) => {
  const chords: ChordVariant[] = []
  for (let i = 0; i < num; i++) {
    const c = randomChord()
    if (c) {
      chords.push(c)
    }
  }
  return chords
}

export const nextChord = ({ current, next, scale }: { current?: Chord; next?: Chord; scale: Scale }): ChordMatch[] => {
  /** Naive implementation for now */
  /** If no next, previous, just choose a random progression */
  if (!next && !current) {
    const chord = randomChordInKey(scale)
    return [chord]
  }

  /** In cache? */
  const key = cacheKey(current, next, scale)
  if (chordCache[key]) {
    return chordCache[key]
  }

  const matches: { [chordName: string]: ChordMatch } = {}

  /** Iterate through the lib and score them */
  for (const progression of CHORD_PROGRESSIONS) {
    /** We want to match a pair of <previous, next> */

    for (const [idx, entry] of Object.entries(progression.sequence)) {
      /** If current is not set, we will not match pc1 */
      const pc1 = current ? entry : undefined
      /** Wrap around */
      const midEntry = progression.sequence[(parseInt(idx, 10) + 1) % progression.sequence.length]
      const nextEntry = progression.sequence[(parseInt(idx, 10) + 2) % progression.sequence.length]
      /** If pc1 is not set (i.e., current is null), use entry as pc2 */
      const pc2 = pc1 ? nextEntry : entry

      const c1 = pc1 ? ScaleLib.getChord(scale, pc1.degree) : undefined
      const c2 = pc2 ? ScaleLib.getChord(scale, pc2.degree) : undefined
      const cNext = ScaleLib.getChord(scale, midEntry.degree)

      const match1 = ChordLib.equals(current, c1)
      let score = match1 ? progression.strength * 0.5 : 0
      const match2 = ChordLib.equals(next, c2)
      score += match2 ? progression.strength * 0.5 : 0

      // logger.debug({idx, cNext, pc1, pc2, c1, c2, current, next, match1, match2, score})

      if (cNext && score > 0) {
        /** Don't overwrite with a lower score */
        const chordName = ChordLib.getName(cNext)
        const replace = matches[chordName] ? score > matches[chordName].score : true
        if (!replace) {
          continue
        }
        matches[chordName] = { chord: cNext, score, name: progression.name }
      }
    }
  }
  let result = Object.values(matches).sort((a, b) => (a.score === b.score ? 0 : a.score > b.score ? -1 : 1))
  if (result.length === 0) {
    result = [randomChordInKey(scale)]
  }
  chordCache[key] = result
  return result
}
