import type { NoteName } from '@tunasong/schemas'

export const NATURAL = '♮'
export const FLAT = '♭'
export const SHARP = '♯'
export const DIMINISHED = '°'

export const prettyPrint = (note: NoteName) =>
  /** Since replaceAll is not reliable yet, we call replace twice for b */
  note.replace('#', SHARP).replace('b', FLAT).replace('b', FLAT).replace('dim', DIMINISHED)
