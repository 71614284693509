import { type Articulation, type Fingering } from '../guitar/library.js'

export enum Duration {
  QuarterNote = 'q',
  HalfNote = 'h',
  WholeNote = 'w',
  EightsNote = '8',
  SixteenthNote = '16',
}

export enum NoteFinish {
  Vibrato = 'v',
  StrongVibrato = 'V',
}

/** https://en.wikipedia.org/wiki/ASCII_tab */
export interface TabStep {
  fingering: Fingering | Fingering[]

  duration?: Duration
  articulation?: Articulation
  finish?: NoteFinish
}

export interface Tab {
  name?: string
  /** Tablang string */
  lang?: string
  steps: TabStep[]
}

const stringLabels = ['e', 'B', 'G', 'D', 'A', 'E']

export const tabToAscii = (tab: Tab) => {
  /** Map out the tab in a sequence. The sequnce is sequence[string][sequenceNo] */
  /** @todo handle instruments with != 6 strings */
  const sequence: (number | '-')[][] = [[], [], [], [], [], []]
  const tabSteps = tab.steps
  tabSteps.forEach((tabStep, idx) => {
    const vals: number[] = [0, 1, 2, 3, 4, 5]
    for (const i of vals) {
      sequence[i][idx] = '-'
    }
    for (const f of Array.isArray(tabStep.fingering) ? tabStep.fingering : [tabStep.fingering]) {
      sequence[f.str - 1][idx] = f.fret
    }
  })

  return sequence.map((s, idx) => `${stringLabels[idx]} |-${s.join('-')}-|`)
}
