import { type ChordNotes } from '../chord/chord-types.js'
import { type Fingering, type Fret } from './fingering.js'

export interface GuitarChord extends ChordNotes {
  capo?: Fret
  fingering: Fingering[]
}

export function isGuitarChord(chord: unknown): chord is GuitarChord {
  return Boolean(
    chord &&
      typeof chord === 'object' &&
      'fingering' in chord &&
      'fingering' in chord &&
      Array.isArray((chord as GuitarChord).fingering) &&
      Array.isArray((chord as GuitarChord).notes)
  )
}
