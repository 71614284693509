import { type Chord, isChordNotes } from './chord-types.js'
import { getNotes } from './get-notes.js'

export const chordEquals = (a?: Chord, b?: Chord) => {
  if (!a || !b) {
    return
  }
  const notesA = isChordNotes(a) ? a.notes : getNotes(a)
  const notesB = isChordNotes(b) ? b.notes : getNotes(b)

  return Boolean(a && b && notesA.every(n => notesB.includes(n)) && notesB.every(n => notesA.includes(n)))
}
