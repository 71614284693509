import { getChordNames } from './library.js'

/**
 * Create a regexp for the chords
 *
 * We reverse the Ids to match the special chords (e.g., Csus2) before the plain chord (C)
 */
const chordIds = getChordNames().reverse()
const chordRegexpStr = `(${chordIds.join('|')})([\n\r\t ]+|$)`
export const chordsRegexp = new RegExp(chordRegexpStr)
