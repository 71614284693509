import type { NoteName } from '@tunasong/schemas'

export type Tuning = Record<number, NoteName>

export const TUNINGS: Record<string, Tuning> = {
  normal: {
    1: 'E',
    2: 'B',
    3: 'G',
    4: 'D',
    5: 'A',
    6: 'E',
  },
  dropd: {
    1: 'D',
    2: 'B',
    3: 'G',
    4: 'D',
    5: 'A',
    6: 'E',
  },
}
